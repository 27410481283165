(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("spritejs"));
	else if(typeof define === 'function' && define.amd)
		define(["spritejs"], factory);
	else if(typeof exports === 'object')
		exports["qcharts"] = factory(require("spritejs"));
	else
		root["qcharts"] = factory(root["spritejs"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 